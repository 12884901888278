import {useState, useEffect, FC, ReactNode} from 'react';
import { useSelector } from 'react-redux';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import {useRouter} from "next/router";
const ReCaptchaWrapper: FC<{children: ReactNode}> = ({ children }) => {
    const recaptcha_active = Number(
        // @ts-ignore
        useSelector((state) => state.general.coreConfigs?.general_recaptcha_general_active?.value)
    );
    const router = useRouter();
    // @ts-ignore
    const recaptcha_key = useSelector((state) => state.general.coreConfigs?.general_recaptcha_general_site_key?.value);
    const [closeReCaptcha, setCloseReCaptcha] = useState<boolean | undefined>(false);

    useEffect(() => {
        if (router.pathname === "/signup") {
            setCloseReCaptcha(true);
        } else {
            setCloseReCaptcha(false);
        }
    }, [router.pathname]);

    return (
        <>
            {recaptcha_active && closeReCaptcha ? (
                <ReCaptchaProvider useEnterprise={true} reCaptchaKey={recaptcha_key}>
                    {children}
                </ReCaptchaProvider>
            ) : (
                children
            )}
        </>
    );
};

export default ReCaptchaWrapper;